/* ========================= */
/*        Global Base        */
/* ========================= */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 0.9rem;
}

/* ========================= */
/*      Login Form Styles    */
/* ========================= */
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.login-field {
    margin-bottom: 15px;
}

.login-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.login-field input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.login-error {
    color: red;
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    cursor: pointer;
}

/* ========================= */
/*   Container & Layout      */
/* ========================= */
.container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 100% !important;
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}

.container.mt-4 {
    margin-top: 1rem !important;
}

/* ========================= */
/*      Table Styling        */
/* ========================= */
.table td,
.table th {
    padding: 0.5rem 0.75rem;
}


/* ========================= */
/*       Headings            */
/* ========================= */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
}


.row-women {
    background-color: #f8cde2 !important;
    /* stronger pink */
}

.row-girls {
    background-color: #cfdffb !important;
    /* slightly deeper blue */
}

.row-boys {
    background-color: #d9efff !important;
    /* aqua-tinted light blue */
}

.row-men {
    background-color: #e2e3e5 !important;
    /* darker grey */
}